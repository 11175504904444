import React from 'react'
import { FiX } from "react-icons/fi";

function Modal({status, onCloseClicked, children, title}) {

    if(status){
        return (
            <div className='flex w-full min-h-screen fixed top-0 left-0 inset-0 z-10 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center'>
                <div className='flex flex-col p-4 rounded-lg bg-white'>
                    <div className="flex flex-row justify-between">
                        <div className='font-medium text-lg'>{title}</div>
                        <button onClick={onCloseClicked} className='px-2 py-2'>
                            <FiX />
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        );
    }else{
        return (
            <></>
        );
    }

}

export default Modal