const Constants = {
    colors: {
        primary:''
    },
    api_key:"6hU6YESWtdF4vDvm",
    //base_url:"http://104.248.61.104/api/"
    base_url:"https://zontadigital.com/api/"
    
}

export default  Constants;