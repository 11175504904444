import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { DropdownComponent, Modal } from '../../components';
import { NagsContext } from '../../context/nagsContext';
import { NagsService } from '../../services';

import NavOutlet from '../../NavOutlet';

function CarTypeScreen() {
    const navigate = useNavigate();

    const nagsService = new NagsService();

    const {years, carMakes, carModels, bodyStyles, loadGlasses, fetchYears, vehicleId, fetchCarMakes, fetchCarModels, 
        fetchBodyStyles, setVehicleId, setSelectedLoadGlass} = useContext(NagsContext);

    const [year, setYear] = useState();
    const [carMake, setCarMake] = useState();
    const [carModel, setCarModel] = useState();
    const [bodyStyle, setBodyStyle] = useState();
    const [loadGlass, setLoadGlass] = useState();

    const [canContinue, setCanContinue] = useState(false);
    // const [showSidePanel, setShowSidePanel] = useState(true);

    const [vin, setVin] = useState();
    const [loadingVinData, setLoadingVinData] = useState();
    const [showVin, setShowVin] = useState(false);

    useEffect(() => {
        if(!vin){
            setCarMake();setCarModel();setBodyStyle();
        }
        fetchYears();
    }, [])

    useEffect(() => {
        if(!vin){
            setCarMake();setCarModel();setBodyStyle();
        }
        if(year){
            fetchCarMakes(year.value);
        }
    }, [year])

    useEffect(() => {
        if(!vin){
            setCarModel();setBodyStyle();
        }
        if(carMake){
            fetchCarModels(year.value, carMake.value);
        }
    }, [carMake])

    useEffect(() => {
        console.log(carModel);
        if(carModel){
            fetchBodyStyles(year.value, carMake.value, carModel.value.model_id, carModel.value.modifier_id);
        }
    }, [carModel])

    useEffect(() => {
        if(bodyStyle && !vin){
            console.log(bodyStyle);
            setVehicleId((prev) => bodyStyle.value);
        }

        if(loadGlass){
            setSelectedLoadGlass((prev) => loadGlass.value);
        }
    }, [bodyStyle, loadGlass])

    useEffect(() => {
        if(year && carMake && carModel && bodyStyle && loadGlass){
            setCanContinue((prev) => true);
        }else{
            setCanContinue((prev) => false);
        }
    }, [year, carMake, carModel, bodyStyle, loadGlass])

    const handleGetDetailsWithVIN = async (vin_data) => {
        console.log(vin_data);

        setLoadingVinData(true);
        await fetchYears();
        setYear((prev) => {return {label:vin_data.year, value:vin_data.year}});

        // Car Make
        await fetchCarMakes(vin_data.year);
        setCarMake((prev) => {return {label: vin_data.make_name, value:vin_data.make_id}});
        

        // Car Model
        const md = {
            model_id: vin_data.model_id,
            model_name: vin_data.model_name,
            modifier_id: vin_data.modifier_id,
            modifier_dsc: vin_data.modifier_dsc
        }
        await fetchCarModels(vin_data.year, md);
        setCarModel((prev) => {return {label: md.model_name + (md.modifier_dsc ? ` ${md.modifier_dsc}` : ''), value: md}});

        // Body Style
        await fetchBodyStyles(vin_data.year, vin_data.make_id, vin_data.model_id, vin_data.modifier_id);
        setBodyStyle((prev) => {return {label: vin_data.body_style_dsc, value: vin_data.body_style_id}});
        setVehicleId((prev) => vin_data.id);

        setLoadingVinData(false);

    }

    const handleVINVerification = () => {
        if(vin && vin.length>=12){
            setLoadingVinData(true);
            nagsService.fetchVehicleDataByVin(vin).then((res) => {
                console.log(res.data);
                handleGetDetailsWithVIN(res.data);
                setShowVin(false);
            }).catch((err) => {
                console.log(err);
            }).finally(() => setLoadingVinData((prev) => false));
        }
    }


    return (
        <NavOutlet>
            <div className='flex flex-col gap-2 w-full px-4 md:px-0 md:w-[450px] xl:w-1/3 items-center'>
                <div className='flex flex-col items-start w-full'>
                    <div className='font-medium text-2xl'>Tell us about your vehicle</div>
                    <div>Find your vehicle by entering the year, make, model, style and load glass or you can search by vehicle identification number (VIN).</div>
                </div>

                <div className='flex flex-row justify-start w-full'>
                    <button onClick={() => setShowVin(true)}
                    className='flex bg-[#98cb46] px-5 py-2 rounded-lg'>
                        <div className='text-white font-medium'>Search by VIN instead?</div>
                    </button>
                </div>

                {loadingVinData && (
                    <div className='flex flex-row gap-1 w-full justify-start items-center'>
                        <img src={require("../../assets/loading.png")} alt="" className='w-4 h-4 animate-spin mr-2' />
                        <div className='text-sm'>Loading data from VIN</div>
                    </div>
                )}

                {/* Years */}
                <DropdownComponent items={years.map((y) => {return {label: y, value: y}})} 
                selectedItem={year} setSelectedItem={setYear} title={'Year'}/>

                {/* Car Makes */}
                <DropdownComponent items={carMakes.length===0 ? [] : carMakes.map((cm) => {return {label: cm.make_name, value: cm.make_id}})} 
                selectedItem={carMake} setSelectedItem={setCarMake} title={'Car Make'}/>

                {/* Car Models */}
                <DropdownComponent items={carModels.map((cmd) => {return {label: cmd.model_name + (cmd.modifier_dsc ? ` ${cmd.modifier_dsc}` : ''), value: cmd}})} 
                selectedItem={carModel} setSelectedItem={setCarModel} title={'Car Model'}/>

                {/* Body Style */}
                <DropdownComponent items={bodyStyles.map((bs) => {return {label: bs.body_style_dsc, value: bs.vehicle_id}})} 
                selectedItem={bodyStyle} setSelectedItem={setBodyStyle} title={'Body Style'}/>

                {/* Load Glasses */}
                <DropdownComponent items={loadGlasses.map((lg) => {return {label: lg, value: lg}})} 
                selectedItem={loadGlass} setSelectedItem={setLoadGlass} title={'Load Glass'}/>

                {/* Buttons */}
                <div className='w-full flex flex-row justify-between mt-4'>
                    <button onClick={() => {
                        // 
                    }}
                    className='bg-gray-100 py-2 px-6 rounded-lg text-gray-900 font-medium flex flex-row justify-center items-center border-2 border-gray-900'>
                        <div>RESET</div>
                    </button>
                    <button disabled={!canContinue} onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/filter/?vehicle_id=${vehicleId}&load_glass=${loadGlass?.value}`);
                        // `/filter/?vehicle_id=${vehicleId}&load_glass=${loadGlass?.value}`
                    }} 
                    className={`py-2 px-6 rounded-lg text-white font-medium flex flex-row justify-center items-center border-2 ` + (canContinue ? 'bg-gray-900 border-gray-900' : 'bg-gray-400 border-gray-400')}>
                        CONTINUE
                    </button>
                </div>

                {/* VIN Modal */}
                <Modal status={showVin} onCloseClicked={() => setShowVin(false)} title={"Enter your VIN"}>
                    <div className='flex flex-col w-[400px] mt-2 gap-3'>
                        <input type="text" onChange={(e) => setVin(e.target.value)} placeholder='Search for VIN' 
                        className='px-2 py-2 rounded-md bg-gray-100 w-full'/>
                        
                        <div className='text-md text-[#98cb46] font-medium'>Where can I find my VIN?</div>
                        <div className='text-sm mb-2'>Your VIN can be found in two locations on the drivers side: bottom corner of the windshield and on the door jamb. It's also on your vehicle registration card.</div>

                        <button disabled={!vin} onClick={handleVINVerification} 
                        className={`py-2 px-6 rounded-lg text-white flex flex-row justify-center items-center w-full ` + (!vin || vin.length<12 ? 'bg-gray-300' : 'bg-[#98cb46]')}>
                            {loadingVinData && <img src={require("../../assets/loading.png")} alt="" className='w-4 h-4 animate-spin mr-2' />}
                            <div>Verify VIN</div>
                        </button>
                    </div>
                </Modal>
            </div>
        </NavOutlet>
    );
}

export default CarTypeScreen