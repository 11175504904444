import React, { useContext, useState } from 'react';

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { NagsContext } from '../context/nagsContext';

function DropdownComponent({items, selectedItem, setSelectedItem, title}) {

    const {itemLoading} = useContext(NagsContext);

    const [openDropDown, setOpenDropDown] = useState(false);

    return (
        <div className="relative inline-block text-left w-full">
            <h3 className='font-medium mb-1 mt-2 text-base'>{title}:</h3>
            <div>
                <button disabled={items.length===0} onClick={() => setOpenDropDown(!openDropDown)}
                className={"flex flex-row items-center w-full justify-between rounded-md px-3 py-3 text-sm shadow-sm ring-1 ring-inset ring-gray-300 " + (items.length===0 ? "bg-gray-100 cursor-not-allowed" : "bg-white hover:bg-gray-50")} 
                id="menu-button" aria-expanded="true" aria-haspopup="true">
                    {itemLoading===title && <img src={require("../assets/loading.png")} alt="" className='w-4 h-4 animate-spin mr-2' />}
                    <div className={selectedItem ? 'text-gray-900 font-semibold' : 'text-gray-500'}>{selectedItem ? selectedItem.label : `Select ${title}`}</div>
                    <div className='flex flex-grow'></div>
                    {items.length>0 && <>{openDropDown ? <FiChevronUp size={16}/> : <FiChevronDown size={16}/>}</>}
                </button>
            </div>

            {openDropDown && (
                <div className={"w-full max-h-[400px] overflow-x-hidden absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " + (items.length>5 && "overflow-scroll")} 
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                    <div className="py-1 w-full" role="none">
                        {items.map((item, index) => {
                            return (
                                <button  onClick={() => {
                                    setSelectedItem(item);
                                    setOpenDropDown((prev) => false);
                                }} key={index}
                                className={"block px-4 py-2 text-sm hover:bg-gray-100 duration-300 w-full text-left " + (item.value === selectedItem?.value ? "text-white font-medium bg-[#98cb46]" : "text-gray-700")}>
                                    {item.label}
                                </button>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default DropdownComponent;