import React from 'react';
import { FiCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";

import { Utils } from '../utils';

function SideStepsPanel() {
    const location = useLocation();
    return (
        <div className='flex flex-col gap-3 px-4 w-full md:w-[250px] lg:w-[300px] border-l'>
            <div className="flex flex-row justify-between items-center border-b pb-2">
                <div className='font-medium text-lg'>
                    STEPS
                </div>
                <div className='font-medium text-lg'>
                    {Utils.getStepNumber(location.pathname)}/3
                </div>
            </div>

            <div className='flex flex-row gap-2 mb-3'>
                <div className="flex flex-col items-center gap-1 pt-1">
                    <FiCircle color='red'/>
                    <div className='min-h-14 flex-grow bg-red-300 w-[2px]'></div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className='font-medium'>Selecting Vehicle Type</div>
                    <div className='text-sm text-gray-600'>Step 1 description lorem empsum</div>
                </div>
            </div>

            <div className='flex flex-row gap-2 mb-3'>
                <div className="flex flex-col items-center gap-1 pt-1">
                    <FiCircle color='gray'/>
                    <div className='min-h-14 flex-grow bg-gray-200 w-[2px]'></div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className='font-medium'>Filtering Glass Options</div>
                    <div className='text-sm text-gray-600'>Step 2 description lorem empsum</div>
                </div>
            </div>

            <div className='flex flex-row gap-2 mb-3'>
                <div className="flex flex-col items-center gap-1 pt-1">
                    <FiCircle color='gray'/>
                    <div className='min-h-14 flex-grow bg-gray-200 w-[2px]'></div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className='font-medium'>Address</div>
                    <div className='text-sm text-gray-600'>Step 2 address information description lorem empsum</div>
                </div>
            </div>

            
        </div>
    );
}

export default SideStepsPanel;