import React, { useContext, useEffect, useState } from 'react'
// import { useParams, } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { NagsContext } from '../../context/nagsContext';

import { FilterComponent } from '../../components';
import useQueryParams from '../../hooks/useQueryParams';
import NavOutlet from '../../NavOutlet';
import { Constants } from '../../utils';

import {Modal} from '../../components';
import { NagsService } from '../../services';

function FilterScreen() {
    const nagsService = new NagsService();
    const {vehicle_id, load_glass} = useQueryParams();

    const {fetchNagsGlassDetails, nagsDetails, getFilteringQualifiers, 
        filteringQualifiers, getValidQualifiers, getCurrentValidQualifier} = useContext(NagsContext);

    // NEW LOGIC
    const [currentValidQualifier, setCurrentValidQualifier] = useState();
    const [contains, setContains] = useState(null);
    const [currentQualifiers, setCurrentQaulifiers] = useState();
    const [newFilteringQualifiers, setNewFilteringQualifiers] = useState();

    const [currNagsData, setCurrNagsData] = useState();

    const [postCode, setPostCode] = useState();
    const [showPostCodePopup, setShowPostCodePopup] = useState(false);
    
    const [doneFiltering, setDoneFiltering] = useState(false);
    
    const [priceData, setPriceData] = useState();
    const [totalAmount, setTotalAmount] = useState(0);
    const [showPricePopup, setShowPricePopup] = useState(false);
    const [loadingAmount, setLoadingAmout] = useState(false);

    useEffect(() => {
        if(priceData){
            let total = 0;
            for (let i = 0; i < priceData['Items'].length; i++) {
                const dt = priceData['Items'][i];
                total += parseFloat(dt['price']);
            }

            setTotalAmount((prev) => total);
        }
    }, [priceData])

    const handleGetPriceData = () => {
        setShowPostCodePopup((prev) => false);
        if(nagsDetails){
            setLoadingAmout(true);
            const skus = [...currNagsData.NagsGlass.map((ng) => {
                return {sku: ng.Configurations[0].nags_part_number}
            })]
            console.log(currNagsData);
            console.log(skus);
            nagsService.fetchPriceData(postCode, '50', skus).then((res) => {
                console.log(res.data);
                setPriceData(res.data[0]);
                setShowPricePopup((prev) => true);
            }).catch((err) => {
                console.log(err);
            }).finally(() => setLoadingAmout(false));
        }
    }

    const updateCurrNagsData = (contains) => {
        let newNgs = currNagsData.NagsGlass;
        let updatedNgs = newNgs.filter((ng) => {
            const qls = ng.Qualifiers.map((ql) => ql.qual_cd);
            if(contains){
                return qls.includes(currentValidQualifier)
            }else{
                return !qls.includes(currentValidQualifier)
            }
        })

        if(updatedNgs.length!==0){
            newNgs.NagsGlass = updatedNgs;
            setCurrNagsData((prev) => newNgs);
        }
    }

    // Runs every time there is a click action on the button "CONTINUE"
    const handleContinueFiltering = () => {
        if(contains!==null){
            // 
            updateCurrNagsData(contains)

            // 1. Get new valid qualifiers,
            const [valQls, newFlQual] = getValidQualifiers(currentQualifiers, newFilteringQualifiers, contains, currentValidQualifier);
            setCurrentQaulifiers((prev) => valQls);
            setNewFilteringQualifiers((prev) => newFlQual);
            // 2. Get new current valid qualifier
            const valCurrQl = getCurrentValidQualifier(newFlQual);
            setCurrentValidQualifier((prev) => valCurrQl);

            setContains(null);

            // console.log(valQls);
            // console.log(valCurrQl);
            // console.log(newFlQual);

            if(valQls.length===0 || newFlQual.length===0 || !valCurrQl){
                setDoneFiltering((prev) => true);
            }
        }
    }

    useEffect(() => {
        fetchNagsGlassDetails(vehicle_id, load_glass);
    }, [])

    useEffect(() => {
        if(nagsDetails){
            getFilteringQualifiers();
            setCurrNagsData(nagsDetails);
        }
    }, [nagsDetails])

    useEffect(() => {
        if(filteringQualifiers && nagsDetails){
            // Runs one time, on the first fetch of qualifiers
            // 1. Get new current valid qualifier
            const currQual = getCurrentValidQualifier(filteringQualifiers);
            setCurrentValidQualifier((prev) => currQual);
            setNewFilteringQualifiers((prev) => filteringQualifiers);

            const qualifiers = [...nagsDetails.NagsGlass].map((glass) => glass.Qualifiers)
            setCurrentQaulifiers((prev) => qualifiers);

            // console.log(qualifiers);
            
        }
    }, [nagsDetails, filteringQualifiers])

    return (
        <NavOutlet>
            <div className='flex flex-col gap-2'>
                {nagsDetails && (
                    <div className='flex flex-col gap-1 items-center'>
                        <div className='font-bold text-xl'>{nagsDetails.make_name}</div>
                        <div className='font-medium'>{nagsDetails.body_style_dsc}</div>
                        <div className='mb-3'>{nagsDetails.model_name} {nagsDetails.modifier_dsc ?? ""}</div>
                        <img src={`${Constants.base_url}fetch_graphics_detail?graphicsId=${nagsDetails.graphic_id}`} alt="" width={350} height={350} />
                    </div>
                )}

                {!nagsDetails && (
                    <div className='flex flex-col items-center gap-2'>
                        <div className='w-[100px] h-8 bg-slate-200 rounded-full'></div>
                        <div className='w-[150px] h-5 bg-slate-200 rounded-full'></div>
                        <div className='w-[100px] h-5 bg-slate-200 rounded-full'></div>

                        <div className='w-[350px] h-[300px] bg-slate-200 rounded-2xl my-2'></div>
                    </div>
                )}

                {currentValidQualifier && currentQualifiers && (
                    <h3 className='font-bold text-xl mt-6'>Filters</h3>
                )}
                {currentValidQualifier && currentQualifiers && (
                    <FilterComponent key={currentValidQualifier} item={currentValidQualifier} checkValue={() => contains} onValueChange={(val) => setContains(val) }/>
                )}

                {doneFiltering && (
                    <div className='py-3 px-2 bg-gray-100 rounded-xl'>
                        {currNagsData.NagsGlass[0].description}
                    </div>
                )}

                {/* Button */}
                <div className="flex flex-row justify-center">
                    {!doneFiltering ? (
                        <button disabled={contains===null} onClick={() => {
                            if(doneFiltering){
                                setShowPostCodePopup((prev) => true);
                            }else{
                                handleContinueFiltering();
                            }
                        }} 
                        className={`py-2 px-6 rounded-lg text-white flex flex-row justify-center items-center ` + (contains===null ? 'bg-[#98cb46] bg-opacity-50' : 'bg-[#98cb46]')}>
                            Continue
                        </button>
                    ) : (
                        <button onClick={() => {
                            if(doneFiltering){
                                setShowPostCodePopup((prev) => true);
                            }else{
                                handleContinueFiltering();
                            }
                        }} 
                        className='bg-[#98cb46] py-2 px-6 rounded-lg text-white flex flex-row justify-center items-center'>
                            {loadingAmount && <img src={require("../../assets/loading.png")} alt="" className='w-4 h-4 animate-spin mr-2' />}
                            {loadingAmount ? 'Getting Price ...' : 'Get Price'}
                        </button>
                    )}
                </div>


                {/* Postcode Modal */}
                <Modal status={showPostCodePopup} onCloseClicked={() => setShowPostCodePopup(false)} title={"Post Code"}>
                    <div className='flex flex-col w-[400px] mt-2 gap-4'>
                        <div className='text-sm'>Please enter your post code to Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, distinctio.</div>
                        <input type="text" onChange={(e) => setPostCode(e.target.value)} placeholder='Post Code' 
                        className='px-2 py-2 rounded-md bg-gray-100 w-full'/>

                        <button disabled={!postCode} onClick={handleGetPriceData} 
                        className='bg-[#98cb46] py-2 px-6 rounded-lg text-white flex flex-row justify-center items-center w-full'>
                            Confirm Code
                        </button>
                    </div>
                </Modal>

                {/* Postcode Modal */}
                <Modal status={showPricePopup} onCloseClicked={() => setShowPricePopup(false)} title={"Price Information"}>
                    <div className='flex flex-col w-[400px] mt-2 gap-4'>
                        <div className="text-lg font-medium">${totalAmount}</div>
                        <div className='text-sm'>This is the total amount to do Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, distinctio.</div>

                        <button disabled={!postCode} onClick={handleGetPriceData} 
                        className='bg-[#98cb46] py-2 px-6 rounded-lg text-white flex flex-row justify-center items-center w-full'>
                            Send data via email
                        </button>
                    </div>
                </Modal>
            </div>
        </NavOutlet>
    );
}

export default FilterScreen;