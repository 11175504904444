import React from 'react';
import { FiMenu } from "react-icons/fi";

import { Link } from 'react-router-dom';

function AppBar({onDrawerClicked}) {
    return (
        <div className="flex flex-row justify-between w-full p-4 mb-4 bg-gray-100">
          <Link to={"/"} className='flex flex-row gap-2 items-center'>
            <div className='w-14 h-14 rounded-md overflow-hidden'>
                <img src={require("../assets/glassgenie.png")} alt="" className='w-full h-full object-cover' />
            </div>
            <div className='font-bold text-xl'>Glass Genie</div>
          </Link>
          <button onClick={onDrawerClicked} 
          className='flex flex-row items-center gap-2'>
            <div>Steps</div>
            <FiMenu />
          </button>
        </div>
    );
}

export default AppBar;