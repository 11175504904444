import React, { useContext } from 'react';

import { NagsContext } from '../context/nagsContext';
import { Utils } from '../utils';


function FilterComponent({item, checkValue, onValueChange}) {
    const {qualifierName} = useContext(NagsContext);

    const notSelectedClassName = 'w-full py-2 bg-gray-200 rounded-full flex justify-center items-center duration-300'
    const selectedClassName = 'border-2 border-red-300'
    
    return (
        <div className='w-[350px] flex flex-col py-2 px-4 rounded-2xl gap-2 items-center bg-gray-50 mb-4'>
            <div>Does your car have?</div>
            <div className='font-bold text-xl'>{Utils.getFullName(item, qualifierName?.get(item).dsc)}:</div>
            <button onClick={() => onValueChange(true)} 
            className={`${notSelectedClassName} ${checkValue()===true ? selectedClassName : 'border-2 border-gray-200'}`}>
                YES
            </button>
            or
            <button onClick={() => onValueChange(false)} 
            className={`${notSelectedClassName} ${checkValue()===false ? selectedClassName : 'border-2 border-gray-200'}`}>
                NO
            </button>
        </div>
    );
}

export default FilterComponent