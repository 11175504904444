import NetworkHelper from "./helper/networkHelper";

const helper = new NetworkHelper();

class NagsService {

    async fetchYears(){
        return helper.get(`fetch_years`);
    }

    async fetchCarMakes(year){
        const data = {
            year: year
        }
        return helper.post(`fetch_car_makes`, data);
    }

    async fetchCarModels(year, carMake){
        const data = {
            year: year,
            carMake: carMake
        }
        return helper.post(`fetch_car_models`, data);
    }

    async fetchBodyStyles(year, carMake, carModel, carModifier){
        const data = {
            year: year,
            carMake: carMake,
            carModel: carModel,
            modifierId: carModifier
        }
        return helper.post(`fetch_body_styles`, data);
    }

    async fetchNagsGlassDetails(vehicleId, loadGlass){
        const data = {
            vehicleId: vehicleId,
            loadGlass: loadGlass
        }
        return helper.post(`fetch_glass_details`, data);
    }

    async fetchPriceData(postalCode, radius, items){
        const data = {
            postalCode: postalCode,
            radius: radius,
            items: items
        }
        return helper.post(`fetch_price_data`, data);
    }

    async fetchVehicleDataByVin(vin){
        const data = {
            vehicleId: vin
        }
        return helper.post(`fetch_vehicle_data`, data);
    }

}

export default NagsService;