import { Constants } from "../../utils";
import axios from "axios";

class NetworkHelper{
    async get(url){
        // const config = {
        //     headers: {'api_key':`${Constants.api_key}`, ...headers, 'Content-Type': 'application/json'}
        // }
        return axios.get(`${Constants.base_url}${url}`);
    }

    async post(url, data={}){
        return axios.post(`${Constants.base_url}${url}`, data);
    }

    async delete(url, id){
        return axios.delete(`${Constants.base_url}${url}/${id}/`);
    }

    async put(url, data, id=''){
        return axios.put(`${Constants.base_url}${url}/${id}`, data);
    }

    async patch(url, data, id=''){
        return axios.patch(`${Constants.base_url}${url}/${id}`, data);
    }
}

export default NetworkHelper;