const Utils = {
    convertDate: (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            return dt.toDateString().split(' ').slice(1).join(', ');
        }
        return "";
    },
    getFullName: (qualifier, defaultName) => {
        // console.log(qualifier);
        let name = "";
        switch (qualifier) {
            case "2216":
                name = "Acoustic Interlayer";
                break;
            case "1976":
                name = "Solar Coated";
                break;
            case "1408":
                name = "Rain Sensor";
                break;
            case "0":
                name = "Encapsulated";
                break;
            case "2650":
                name = "With Heads-up Display";
                break;
            case "2418":
                name = "Lane Departure Warning System";
                break;
            case "1906":
                name = "3rd Visor Frit";
                break;
            case "3064":
                name = "W/Intelligent HiBeams";
                break;
            case "435":
                name = "Electrochromic Mirror";
                break;
            case "2049":
                name = "Rain/Light Sensor";
                break;
            case "6271":
                name = "Active Protection System";
                break;
            case "6050":
                name = "Forward Collision Alert W/City Crash Mitigation";
                break;
            default:
                name = defaultName;
                break;
        }
        return name;
    },
    getStepNumber: (path) => {
        let stepNum = 1;
        switch (path) {
            case "/":
                stepNum = 1;
                break;
            case "/filter/":
                stepNum = 2;
                break;
            default:
                break;
        }
        return stepNum;
    }
}

export default Utils;