import React from 'react'
import { createContext, useState } from "react";

import { NagsService } from '../services';

export const NagsContext = createContext(null);

function NagsContextProvider(props) {
    const nagsService = new NagsService();

    const [itemLoading, setItemLoading] = useState('');

    const [years, setYears] = useState([]);
    const [carMakes, setCarMakes] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [bodyStyles, setBodyStyles] = useState([]);

    const [loadGlasses, setLoadGlasses] = useState(["WINDSHIELD", "SIDE", "REAR"]);
    const [selectedLoadGlass, setSelectedLoadGlass] = useState();

    const [vehicleId, setVehicleId] = useState();

    const [nagsDetails, setNagsDetails] = useState();
    const [filteringQualifiers, setFilteringQualifiers] = useState([]);
    const [qualifiersCount, setQualifiersCount] = useState();
    
    const [qualifierName, setQualifierName] = useState();

    const fetchYears = async () => {
        setItemLoading((prev) => 'Year');
        setCarMakes((prev) => []);
        setCarModels((prev) => []);
        setBodyStyles((prev) => []);
        nagsService.fetchYears().then((res) => {
            setYears((prev) => res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setItemLoading((prev) => ''));
    }

    const fetchCarMakes = async (year) => {
        setItemLoading((prev) => 'Car Make');
        setCarModels((prev) => []);
        setBodyStyles((prev) => []);
        nagsService.fetchCarMakes(year).then((res) => {
            // setYears(res.data);
            console.log(res.data);
            setCarMakes((prev) => res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setItemLoading((prev) => ''));
    }

    const fetchCarModels = async (year, carMake) => {
        setItemLoading((prev) => 'Car Model');
        setBodyStyles((prev) => []);
        nagsService.fetchCarModels(year, carMake).then((res) => {
            setCarModels((prev) => res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setItemLoading((prev) => ''));
    }

    const fetchBodyStyles = async (year, carMake, carModel, carModifier) => {
        setItemLoading((prev) => 'Body Style');
        nagsService.fetchBodyStyles(year, carMake, carModel, carModifier).then((res) => {
            setBodyStyles((prev) => res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setItemLoading((prev) => ''));
    }


    const fetchNagsGlassDetails = async (v_id, l_glass) => {
        console.log(v_id);
        console.log(l_glass);
        nagsService.fetchNagsGlassDetails(v_id, l_glass).then((res) => {
            setNagsDetails((prev) => res.data);
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }
    
    const getFilteringQualifiers = () => {
        const qualifiers = [...nagsDetails.NagsGlass].map((glass) => glass.Qualifiers);

        // console.log(qualifiers);

        let unique_qls = new Map();
        for (let i = 0; i < qualifiers.length; i++) {
            const qls = qualifiers[i];
            for (let j = 0; j < qls.length; j++) {
                const ql = qls[j];
                if(!unique_qls.has(ql.qual_cd)){
                    unique_qls.set(ql.qual_cd, ql);
                }
            }
        }

        setQualifierName(unique_qls);

        let my_filters = new Map();
        for (let i = 0; i < qualifiers.length; i++) {
            const qls = qualifiers[i];
            for (let j = 0; j < qls.length; j++) {
                const ql = qls[j];
                if(!my_filters.has(ql.qual_cd)){
                    my_filters.set(ql.qual_cd, 1);
                }else{
                    my_filters.set(ql.qual_cd, my_filters.get(ql.qual_cd)+1);
                }
            }
        }

        setQualifiersCount((prev) => my_filters);
        console.log(my_filters);

        let needs_filtering = [];
        my_filters.forEach((values, keys) => {
            if(values<qualifiers.length){
                needs_filtering.push(unique_qls.get(keys))
            }
        })

        setFilteringQualifiers((prev) => needs_filtering);
    }


    // Returns a qualifier_id with small number of appearances
    const getCurrentValidQualifier = (fl_qualifiers) => {
        let min_num = null;
        let min_val = null;
        for (let i = 0; i < fl_qualifiers.length; i++) {
            const ql = fl_qualifiers[i];
            const num = qualifiersCount.get(ql.qual_cd);

            if((min_num===null || min_val===null) && num!==0){
                min_num = num;
                min_val = ql.qual_cd;
            }else{
                if(num<min_num && num!==0){
                    min_num = num;
                    min_val = ql.qual_cd;
                }
            }
        }

        return min_val;
    }

    
    // Returns a valid list of qualifiers based on the given params
    const getValidQualifiers = (qualifiers, fl_qualifiers, contains=false, qual_cd) => {
        let valid_qualifiers = [];
        for (let ct = 0; ct < qualifiers.length; ct++) {
            // qual_cd, description, id 
            let containsQl = false;
            const qlfs = qualifiers[ct];
            for (let ql_ct = 0; ql_ct < qlfs.length; ql_ct++) {
                const ql = qlfs[ql_ct];
                if(ql.qual_cd===qual_cd){
                    containsQl = true;
                }
            }

            if(contains && containsQl){
                valid_qualifiers.push(qlfs);
            }

            if(!contains && !containsQl){
                valid_qualifiers.push(qlfs);
            }
        }

        
        let newFlMap = new Map();
        for (let j = 0; j < valid_qualifiers.length; j++) {
            const vq = valid_qualifiers[j];
            for (let k = 0; k < vq.length; k++) {
                const q = vq[k];
                newFlMap.set(q.qual_cd, 0);
            }
        }
        
        let new_filtering_qual = [];
        for (let ql = 0; ql < fl_qualifiers.length; ql++) {
            const fl_qual = fl_qualifiers[ql];
            if(fl_qual.qual_cd!==qual_cd && newFlMap.has(fl_qual.qual_cd)){
                new_filtering_qual.push(fl_qual);
            }
        }

        return [valid_qualifiers, new_filtering_qual];
    }

    const contextValues = {years, carMakes, carModels, bodyStyles, vehicleId, loadGlasses, selectedLoadGlass, 
        setSelectedLoadGlass, setVehicleId, fetchYears, fetchCarMakes, fetchCarModels, fetchBodyStyles, fetchNagsGlassDetails,
        nagsDetails, getFilteringQualifiers, filteringQualifiers, getValidQualifiers, getCurrentValidQualifier, qualifierName,
        itemLoading};

    return (
        <NagsContext.Provider value={contextValues}>
            {props.children}
        </NagsContext.Provider>
    );
}

export default NagsContextProvider;