import React, { useState } from 'react';

import { AppBar, SideStepsPanel } from './components';

function NavOutlet({children}) {
    const [showSidePanel, setShowSidePanel] = useState(true);
    return (
        <div className='flex flex-1 flex-col justify-start items-center min-h-screen w-full'>
            <AppBar onDrawerClicked={() => setShowSidePanel(!showSidePanel)}/>
            <div className='flex flex-col md:flex-row justify-center w-full gap-2'>
                <div className='hidden md:flex flex-grow'></div>
                {children}
                <div className='hidden md:flex flex-grow'></div>
                {showSidePanel && <SideStepsPanel/>}
            </div>
        </div>
    );
}

export default NavOutlet