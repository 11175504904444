import { BrowserRouter, Routes, Route } from "react-router-dom";

import { CarTypeScreen, FilterScreen } from "./screens";
import NagsContextProvider from "./context/nagsContext";

function App() {
  return (
    <NagsContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CarTypeScreen/>}/>
          <Route path="/filter" element={<FilterScreen/>}/>
        </Routes>
      </BrowserRouter>
    </NagsContextProvider>
  );
}

export default App;
